<template>
  <!-- Turnover 管理 -->
  <div class="app-container">
    <el-form ref="form" :model="form" label-width="80px" style="display: flex;">
      <div class="el-lt" style="width:85%;">
        <el-row :span="24" class="row-input" :class="{ 'show-row': showRow }">
          <el-col :span="8">
            <el-form-item label="STYLE">
              <el-select v-model="form.style" value-key="id" clearable filterable>
                <el-option
                  v-for="item in styleOptions"
                  :key="item.id"
                  :label="item.styleName"
                  :value="item.styleName"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :span="24" class="mb-3">
          <el-col :span="24">
            <router-link to="added" append><el-button class="mr-3" type="primary">新增</el-button></router-link>
            <el-button type="primary" @click="downTemplate">模板下载</el-button>
            <el-upload
              ref="uploadForm"
              action=""
              class="el-button padding-none"
              :http-request="uploadFile"
              :limit="1"
              :show-file-list="false"
              :file-list="fileList"
              :auto-upload="false"
              :on-change="fileChange"
            >
              <el-button type="primary">导入</el-button>
            </el-upload>
            <!-- <el-button>导出</el-button> -->
          </el-col>
        </el-row>
      </div>
      <el-row class="el-rt" style="width:25%;">
        <el-col class="row-center">
          <el-button type="primary" @click="queryClick()">{{ $t('page.search') }}</el-button>
          <el-button @click="resetClick()">{{ $t('page.reset') }}</el-button>
          <el-button type="text" @click="showRowClick()">{{ showRow ? $t('page.hide') : $t('page.expand') }}</el-button>
        </el-col>
      </el-row>

    </el-form>
    <el-table
      ref="multipleTable"
      class="mb-3"
      show-summary
      :data="tableDatas"
      :header-cell-style="{background:'#fafafa',color:'#606266'}"
    >
      <el-table-column
        type="selection"
        width="60"
      />
      <el-table-column
        :label="$t('page.No')"
        type="index"
        width="80"
        align="center"
        sortable
      >
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        prop="category1Code"
        label="商品一级类别"
        width="150"
        align="center"
        sortable
      />
      <el-table-column
        prop="style"
        label="STYLE"
        width="140"
        align="center"
        sortable
      />
      <el-table-column
        prop="turnover"
        label="TURNOVER"
        width="140"
        align="center"
        sortable
      />
      <el-table-column
        prop="orderDay"
        label="下单时间"
        width="140"
        align="center"
        sortable
      />

      <el-table-column
        prop="ltDay"
        label="L/T时间"
        width="140"
        align="center"
        sortable
      />

      <el-table-column
        prop="warehouseProcessDay"
        label="国内仓库处理时间"
        width="180"
        align="center"
        sortable
      />
      <el-table-column
        prop="transportDay"
        label="运输时间"
        width="140"
        align="center"
        sortable
      />
      <el-table-column
        prop="platformShelfDay"
        label="平台上架时间"
        width="160"
        align="center"
        sortable
      />
      <el-table-column
        prop="safetyInventory"
        label="安全库存"
        width="120"
        align="center"
        sortable
      />
      <el-table-column
        fixed="right"
        :label="$t('page.operate')"
        width="90"
        align="center"
        sortable
      >
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="handelturnover(scope.row.id,true)">查看
            <!-- <p v-if="scope.row.pickStatus">查看</p> -->
          </el-button>
          <el-button type="text" size="small" @click="handelturnover(scope.row.id,false)">修改
            <!-- <p v-if="scope.row.pickStatus">修改</p> -->
          </el-button>

        </template>
      </el-table-column>

    </el-table>
    <div class="block">
      <pagination
        :pager="pager"
        @pagination="_turnoverpage"
      />
    </div>
    <!-- 导入 -->
    <el-dialog title="导入" :visible.sync="importDialog" width="550px" :close-on-click-modal="false" :before-close="closeImportDialog">
      <span v-if="fileList != ''" class="info-message">{{ $t('page.ConfirmImport') }} {{ fileList[0].name }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeImportDialog">取消</el-button>
        <el-button type="primary" @click="importDialogSubmit">导入详情</el-button>
      </span>
    </el-dialog>
    <!-- 导入详情 -->
    <el-dialog :visible.sync="uploadTableVisible" width="80%" title="导入详情" append-to-body>
      <el-button type="primary" @click="handleDeletedetails()">删除</el-button>
      <el-table
        ref="uploadTableDataRef"
        :data="importdata"
        :header-cell-style="{background:'#fafafa'}"
        tooltip-effect="dark"
        max-height="550px"
        :row-class-name="tableRowClassName"
        @selection-change="dialogSelectionChange"
      >
        <el-table-column
          type="selection"
          width="60"
          align="center"
        />
        <el-table-column
          :label="$t('page.No')"
          type="index"
          width="80"
          align="center"
        >
          <template slot-scope="scope">{{ (pager.current - 1) * pager.size + scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column
          prop="error"
          label="错误信息"
        />
        <el-table-column
          prop="style"
          label="STYLE"
        />
        <el-table-column
          prop="turnover"
          label="TURNOVER"
        />
        <el-table-column
          prop="orderDay"
          label="下单时间"
        />

        <el-table-column
          prop="ltDay"
          label="L/T时间"
        />

        <el-table-column
          prop="warehouseProcessDay"
          label="国内仓库处理时间"
        />
        <el-table-column
          prop="transportDay"
          label="运输时间"
        />
        <el-table-column
          prop="platformShelfDay"
          label="平台上架时间"
        />
        <el-table-column
          prop="safetyInventory"
          label="安全库存"
        />

      </el-table>
      <el-row type="flex" justify="end" style="margin-top:20px;">
        <el-button @click="uploadTableVisible = false">取消</el-button>
        <el-button type="primary" :loading="Loading" @click="submitImportTable">提交</el-button>
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
import { turnoverimport, addturnoverbatch, findDownloadUrl, queryStyleList, turnoverpage } from '@/api/scm-api'
import { downloads } from '@/utils'
import Pagination from '@/components/Pagination'

export default {
  components: { Pagination },
  data() {
    return {
      styleOptions: [],
      editRow: {},
      showRow: false, // 切换显示input
      importdata: [],
      importdialog: false,
      importDetaildialog: false,
      tableDatas: [],
      sidvoList: [],
      Loading: false,
      pager: {
        size: 10,
        current: 1,
        total: 0
      },
      file: '',
      fileList: [],
      form: {
      },
      importDialog: false,
      uploadTableVisible: false
    }
  },
  computed: {
    // 生成对应的请求参数
    queryParameter() {
      return Object.assign({}, this.pager, this.form)
    }
  },
  mounted() {
    this._queryStyleList()
    this._turnoverpage()
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {
      if (row.error) {
        return 'warning-row'
      }
      return ''
    },
    // 模板下载
    async downTemplate() {
      const { datas } = await findDownloadUrl('STYLE_TURNOVER_IMPORT_TEMPLATE')
      downloads(datas)
    },
    // 获取style数据
    async _queryStyleList() {
      const { datas } = await queryStyleList()
      this.styleOptions = datas
    },
    // 点击查询获取信息
    queryClick(page) {
      this.pager.current = 1
      this._turnoverpage(this.queryParameter)
    },
    // 点击重置清空文本框信息
    resetClick() {
      this.form = {}
      this.queryClick(1)
    },
    // 点击展开控制文本框的显示隐藏
    showRowClick() {
      this.showRow = !this.showRow
    },
    // 默认查询
    async _turnoverpage(pager) {
      try {
        this.TableLoading = true
        pager && Object.assign(this.pager, pager)
        const { datas } = await turnoverpage(this.queryParameter)
        this.tableDatas = datas.records
        this.pager = datas.pager
      } finally {
        this.TableLoading = false
      }
    },

    // 修改、查看
    handelturnover(row, disabled) {
      this.$router.push({
        path: 'added',
        append: true,
        query: { 'id': row, 'disabled': disabled }
      })
    },
    // 导入预览
    beforeUpload(file) {
      const Xls = file.name.split('.')
      const isLt2M = file.size / 1024 / 1024 < 10
      if (!isLt2M) {
        this.$message.error(this.$t('page.UploadSizeTips'))
        return false
      }
      if (Xls[Xls.length - 1] === 'xls' || Xls[Xls.length - 1] === 'xlsx') {
        return true
      } else {
        this.$message.error(this.$t('page.UploadTypeTips'))
        return false
      }
    },
    // 导入详情
    async uploadFile() {
      const form = new FormData()
      form.append('file', this.file)
      const { datas } = await turnoverimport(form)
      datas.map(v => {
        v.outPairs = v.unSalesPairs
      })
      this.importdata = datas
      this.uploadTableVisible = true
      // 隐藏弹窗清空数据
      this.file = ''
      this.fileList = []
      this.importDialog = false
    },
    fileChange(file, fileList) {
      this.fileList = fileList
      this.file = file.raw
      if (this.fileList.length !== 0) {
        if (this.beforeUpload(file)) {
          this.importDialog = true
        }
      } else {
        this.importDialog = false
      }
    },
    closeImportDialog() {
      this.file = ''
      this.fileList = []
      this.importDialog = false
    },
    importDialogSubmit() {
      this.$refs.uploadForm.submit()
    },
    dialogSelectionChange(val) {
      let data = ''
      this.sidvoList = []
      for (let i = 0; i < val.length; i++) {
        data = val[i]
        this.sidvoList.push(data)
      }
    },
    // 导入详情明细删除
    handleDeletedetails() {
      const data = this.$refs.uploadTableDataRef.selection
      data.map(e => {
        var index = this.importdata.findIndex((i) => {
          return e.sku === i.sku
        })
        if (index !== -1) {
          this.importdata.splice(index, 1)
        }
      })
    },
    // 提交导入预览数据
    submitImportTable() {
      if (this.sidvoList.length === 0) {
        this.$message({
          message: '请至少选择一条明细导入',
          type: 'warning'
        })
        return false
      }
      for (let i = 0; i < this.sidvoList.length; i++) {
        if (!(this.sidvoList[i].error === null || this.sidvoList[i].error === '')) {
          this.$confirm('列表中有错误信息不允许提交', '提示', {
            confirmButtonText: '确定'
          })
          return
        }
      }
      this._addturnoverbatch(this.sidvoList)
    },
    // 提交数据接口
    async _addturnoverbatch(data) {
      try {
        this.Loading = true
        const { code, msg } = await addturnoverbatch(data)
        if (code === 0) {
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
          this.Loading = false
          this.uploadTableVisible = false
        }
      } finally {
        this.uploadTableVisible = false
        this.Loading = false
      }
    }

  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-table .warning-row {
  color: red !important;
}
</style>
